.upcomming_services{
    margin-top: 8rem;
    overflow-x: hidden;

}

.upcomming_section {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 2rem;
    margin: 2rem 0;
}
.new_services {
    border-radius: 2rem;
    background: var(--white);
    box-shadow: 0px 6.859142303466797px 6.859142303466797px 0px rgba(74, 181, 229, 0.25) inset, 0px 6.859142303466797px 6.859142303466797px 0px rgba(74, 181, 229, 0.25);
    padding: 1.5rem;
}
.new_services img{
    max-width: 70%;
    margin-bottom: 1rem;
}
.new_services h5{
    text-align: center;
}
.upcomming_img{
    display: flex;
    justify-content: center;
}


/* Media Query */
@media (max-width:1024px){
    .upcomming_services h2{
        font-size: 2rem;
        line-height: 3rem;
    }
}
@media (max-width:425px){
    .upcomming_services h6{
        font-size: 0.85rem;
    }    
    .upcomming_services h2{
        font-size: 1.50rem;
        line-height: 2rem;
    }
    .upcomming_section {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}
@media (max-width:320px){
    .upcomming_services h2{
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
    .upcomming_services h6{
        font-size: .75rem;
    }
}