.register_section{
    margin-top: 8rem;
}
.register_section .heading{   
    width: 30%;
    margin: 1.5rem auto;
    clip-path: polygon(4% 0, 100% 0%, 96% 100%, 0% 100%);    
    background: var(--secondary);
}
.register_section h2{
    display: grid;
    align-items: center;
    justify-content: center;    
    color: var(--white);
    font-size: 2.5rem;
    font-weight: 500;
    padding: .5rem 0rem;   
}
.card img{
    /* width: 50%; */
    padding: 1rem;
}
.register_carousel{
    margin: 5rem 0rem;
}
.react-multi-carousel-list{
    width: 80%;
    margin: auto;
}

@media (max-width:1250px){
    .register_section .heading {
        width: 50%;
    }
    
}
@media (max-width:900px){
    .register_section .heading h1{
        font-size: 1.5rem;
    }    
}
@media (max-width:750px){
    .register_section .heading {
        width: 60%;
    }    
}
@media (max-width:425px){
    .register_section .heading {
        width: 100%;
    }    
}
@media (max-width:425px){
    .register_section .heading h1{
        font-size: 1.2rem;
        padding: 1rem 0rem;
    }    
}

