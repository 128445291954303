footer {
    width: 100%;
    background-color: var(--primary);
    margin-top: 7rem;
    padding: 5rem 0 1rem 0;
    font-size: 0.9rem;
    color: var(--white);
    overflow-x: hidden;
}

.footer__container {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: 4rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.footer-logo {
    margin-left: -0.8rem;
}

.footer__container article:nth-child(2) h4 {
    display: flex;
    width: 15rem;
    /* margin-bottom: 1.5rem; */
}
.margin_10{
    margin: 20px 0px;
}
.contact-info a {
    color:var(--white)
}
/* .footer__container article p {
    margin-top: 0.5rem;
} */

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

/* .imp_heading {
    margin-bottom: 2.2rem !important;
} */


.footer__social {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.footer__Others {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    align-items: center;
}

.footer__copyright {
    color: var(--white);
    text-align: center;
    padding-top: 2rem;
    margin-top: 4rem;
    border-top: 2px solid var(--divider-2);
}

.imp__links {
    color: var(--light_white);
    display: inline-block;
    margin-top: 1rem;
}
.other-list{
    display: flex;
    gap: 1rem;
    margin-top: -1rem;
}
.oth__links {
    color: var(--light_white);
    display: inline-block;
    margin-bottom: 1rem;
}
.footer__social a svg {
    /* fill: linear-gradient(309deg, rgba(255, 255, 255, 0.90) 5.21%, #5BBBD9 100%); */
    color: #fff;
    height: 1.4rem;
    width: 1.4rem;
}
.footer__social a svg:hover {
    color: var(--secondary);
}
/* .imp__links:hover {
    color: var(--secondary);
    transition: var(--transition);
} */

.contact-icons {
    width: 1.2rem;
}

.contact-footer-info .contact-info {
    display: flex;
    align-items: center;
}

.contact-footer-info .contact-info img {
    margin-right: 1rem;
}
.contact-footer-info .contact-icons-add {
    width: 1.2rem;
    margin-top: -1rem;
}
/* =========Media Querries ======= */

@media screen and (max-width:1024px) {
    .footer__container {
        grid-template-columns: 1fr;
        gap: 4rem;
    }

    .footer__container article:nth-child(3) {
        margin-left: 0%;
        width: 18rem;
    }
}