img {
  vertical-align: middle;
}

input,
textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}


textarea {
  resize: vertical;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
}

.row>* {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
}

.col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-12 {
  width: 100%;
}

@media (min-width:576px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-sm-6 {
    width: 50%;
  }
}

@media (min-width:1200px) {
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}


@media (min-width:1200px) {
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .text-xl-start {
    text-align: left !important;
  }
}

button:focus,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]) {
  max-width: 100%;
  height: auto;
}

button {
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

h3 {
  color: var(--secondary);
  text-transform: none;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 0;
}


h3 {
  font-size: 36px;
}

@media (max-width: 1199px) {
  
  h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  
  h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1300px) {
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}

@media (min-width: 1199px) {
  .row:not([class*="gx-"]) {
    --bs-gutter-x: 30px;
  }
}

.form-control,
textarea,
input {
  height: 55px;
  padding: 0 20px 0 20px;
  /* padding-right: 45px; */
  border: 1px solid var(--border-color);
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 9999px;
  border-radius: 0;
  font-size: 15px;
  width: 100%;
}

.form-control:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white-color);
  border-color: var(--theme-color);
}

.form-control::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-btn .btn{
  min-width: 100%;
}

input[type="number"] {
  -moz-appearance: textfield;
  color: var(--ternary);
}

textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 0;
}

.background-image {
  background-image: url(../../assets/images/form_background_img.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.booking-form .row {
  --bs-gutter-x: 20px;
}

@media (max-width: 991px) {
  .booking-form {
    padding: 40px 20px;
  }
}



.booking-form {
  position: relative;
  border-radius: 10px;
  border: none;
  padding: 3rem 3rem 3rem 5rem;
  box-shadow: none;
  z-index: 2;
  background-size: 100% 100%;
  /* margin-left: -25px; */
  /* min-width: 32rem; */
  min-height: 581px;
}

@media (max-width: 1299px) {
  .booking-form {
    padding: 50px 50px 50px 80px;
    min-width: 500px;
    margin-left: 0;
  }
}

@media (max-width: 1199px) {
  .booking-form {
    padding: 50px 50px 50px 120px;
  }
}


@media (max-width: 991px) {
  .booking-form {
    padding: 2rem 2rem 2rem 3rem;
  }
}

@media(max-width:625px){
  .booking-form{
    min-width: 100%;
  }
}

@media (max-width: 575px) {
  .booking-form {
    min-width: 100%;
    min-height: 100%;
    background-image: none !important;
    background-color: var(--white);
    box-shadow: 0px 6px 25px rgba(1, 19, 60, 0.09);
    padding: 2rem 1rem;
    border-radius: 10px;
  }
}

.booking-form .form-control,
.booking-form textarea,
.booking-form input {
  height: 56px;
  border-radius: 30px;
  border: 0.932px solid #E7E7E7;
  background: #FFF;
  color: var(--ternary);

}