.Notfound__container{
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
}
.Notfound__container img{
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.Notfound__container h1{
    font-size: 2rem;
    color: var(--secondary);
    flex-direction: column;
    text-align: center;
    margin-top: 1rem;
}
.Notfound__container p{
    width: 50%;
    font-size: 1rem;
    margin: 0 auto;
    color: var(--secondary);
    flex-direction: column;
    text-align: center;
    margin-top: 1rem;
}
.Notfound__container .GoTOHomeBtn{
    display: flex;
    align-items: center;
    color: var(--white);
    width: 11rem;
    background-color: var(--primary);
    height: 2.5rem;
    border-radius: 20rem;
    padding:0 1rem;
    cursor: pointer;
    margin: 1rem auto;
    box-shadow: var(--box-shadow);
}
.Notfound__container .GoTOHomeBtn:hover{
    background-color: var(--secondary);
}
@media (max-width:1440px) {
    .Notfound__container img{
        width: 80%;
    }
}
@media (max-width:1024px) {
    .Notfound__container img{
        width: 100%;
    }
    .Notfound__container p{
        width: 80%;
    }
}
@media (max-width:425px) {
    .Notfound__container h1{
        font-size: 2rem;
    }
    .Notfound__container p{
        width: 100%;
        font-size: 0.8rem;
    }
}
@media (max-width:375px) {
    .Notfound__container h1{
        font-size: 1.7rem;
    }
}
@media (max-width:325px) {
    .Notfound__container h1{
        font-size: 1.2rem;
    }
}