.services_offer_section {
    margin-top: 8rem;
}

.services_heading {
    text-align: center;
    margin: 2rem 0rem;    
}

.services_heading h5 {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
}

.services_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
    row-gap: 2rem;
    overflow: hidden;
}
.service_wrapper_card{
    overflow: hidden;
    place-items: center;
    border-bottom: 6px solid var(--primary);
    background: rgba(50, 197, 244, 0.10);
    box-shadow: 0px 4px 4px 0px rgba(50, 197, 244, 0.20) inset;
    padding: 1.5rem 2rem;
    border-radius: .75rem;
}
.services_inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    
}

.service_wrapper_card h3 {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.5rem;
    margin-bottom: .75rem;
}

.services_inner_content p {
    color: var(--secondary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
}

.services_inner img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.services_button {
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 1rem;
    /* place-content: end; */
}

.services_button .readMore_btn {
    border-radius: 2rem;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    background: var(--primary);
    box-shadow: 0px 4px 4px 0px rgba(50, 197, 244, 0.25);
    color: #fff;
    font-size: .80rem;
}

.services_button .readMore_btn:hover {
    background: var(--secondary);
}


/* Media Query */
@media (max-width:1024px) {
    .services_inner {
        gap: 3rem;
    }
    
    .services_inner_content p {
        font-size: 0.8rem;
}
    .services_inner img {
        width: 50%;
        margin-left: 5rem;
    }
    .services_section{
        column-gap: 2rem;
        row-gap: 2rem;
    }
     .about_main_section {
        grid-template-columns: 1fr;
    }

    .services_section {
        grid-template-columns: 1fr;
    }

}

@media (max-width:768px) {
   
    .services_inner {
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
    }
    .services_inner img {
        width: 70%;
        margin-left: 0;
    }
}

@media (max-width:700px) {
   
    .services_inner {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 1rem;
        align-items: center;
        justify-content: center;
    }
    .services_inner img {
        width: 50%;
    }
}

@media (max-width:385px) {
   
    .services_button{
        flex-direction: column;
        align-items: self-start;
    }
    .services_inner_content p {
        text-align: justify;
}
}

@media (max-width:325px) {
   
    .services_inner img {
        width: 90%;
    }
}
