.missionvision__container {
    background: var(--light-primary);
    padding: 5rem 0rem;
    margin: 3rem 0;
}

.missionvision__content>span {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
}
.MissionVisionValue{
    display: grid;
    flex-direction: column;
}
.MissionVisionValue_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-top: 2rem;
}

.value {
    position: relative;
}

.value .value_inner_content,.value .vision_inner_content, .value .mission_inner_content {
    position: absolute;
    top: 35%;
    left: 21%;
    margin-right: 20%;
    text-align: center;
}
.value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
    font-size: 1.5rem;
}
.value_inner_content p,.vision_inner_content p, .mission_inner_content p {
    font-size: 0.9rem;
    color: var(--secondary);
}

.react-multiple-carousel__arrow--right, .react-multiple-carousel__arrow--left {
    z-index: 0 !important;
}

@media (max-width:1440px) {
    .MissionVisionValue_wrap {
        /* grid-template-columns: repeat(2, 1fr); */
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 1.5rem;
        margin-top: -0.5rem;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .8rem;
    }
 
}
@media (max-width:1330px){
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 1.2rem;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .7rem;
    }
}
@media (max-width:1024px){
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 1rem;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .6rem;
    }
}
@media (max-width:975px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 40%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 35px;
    }    
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: 1.2rem;
    }
    .MissionVisionValue_wrap {
        grid-template-columns: 1fr;
        gap: 0rem;
    }
}

@media (max-width:554px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 35%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 30px;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: 1rem;
    }
}
@media (max-width:476px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 35%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 25px;
    }    
}
@media (max-width:430px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 32%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 20px;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .8rem;
    }    
}

@media (max-width:400px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 35%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 18px;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .6rem;
    } 
    .MissionVisionValue_wrap {
        gap: 2rem;
    }
}
@media (max-width:340px) {
    .MissionVisionValue_wrap {
        gap: 1rem;
    }
}
@media (max-width:271px) {
    .value .value_inner_content, .value .vision_inner_content, .value .mission_inner_content {
        top: 35%;        
    }
    .value_inner_content h3, .vision_inner_content h3, .mission_inner_content h3 {
        font-size: 15px;
    }
    .value_inner_content p, .vision_inner_content p, .mission_inner_content p {
        font-size: .6rem;
    } 
}