@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    list-style: none;
    outline: none;
    box-sizing: border-box;
}

:root {
    --primary: #00A0EB;
    --secondary: #1D2D5C;
    --ternary: #616C8D;
    --white: #ffffff;
    --light-primary: #32C5F433;
    --light_white: rgba(255, 255, 255, 0.9);
    --divider-1: rgba(255, 255, 255, 0.1);
    --divider-2: rgba(255, 255, 255, 0.5);

    --container-width-lg: 80%;
    --container-width-md: 90%;
    --service-box-shadow: 0px 6.8px 6.8px 0px rgba(74, 181, 229, 0.25) inset, 0px 6.8px 6.8px 0px rgba(74, 181, 229, 0.25);
    --transition: all 500ms ease;
}

body {
    font-family: 'Poppins', sans-serif;
    line-height: 1.7;
    overflow-x: hidden;
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

h1 {
    font-size: 3rem;
}

h2 {
    color: var(--secondary);
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 3.375rem;
    /* text-transform: capitalize; */
}

h6 {
    color: #00A0EB;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

.btn-1 {
    display: inline-flex;
    background-color: var(--white);
    color: var(--primary);
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 2px solid var(--primary);
    cursor: pointer;
}

.btn-1:hover {
    background-color: var(--primary);
    color: var(--white);
    font-weight: 400;
}

.download__store {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}


.BtnFunctionality {
    display: grid;
    grid-template-columns: auto;
    z-index: 999 !important;
}

.TopToUpBtn {
    bottom: 15%;
    right: 2%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--secondary);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    z-index: 100 !important;
    visibility: hidden;
}

.TopToUpBtn:hover {
    background-color: var(--ternary);
}

.WhatsappTap {
    bottom: 8%;
    right: 2%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgb(28, 235, 28);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    z-index: 100 !important;
    visibility: hidden;
}

/* .MailTap {
    bottom: 5rem;
    right: 5%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgb(28, 76, 235);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    z-index: 100 !important;
    visibility: hidden;
} */

@media screen and (max-width:1440px) {
    .WhatsappTap{
        bottom: 5%;
    }
}

@media screen and (max-width:1024px) {
    .btn-1 {
        margin-top: 1rem;
    }
    .MailTap {
        bottom: 8rem;
        right: 2%;
    }
    .TopToUpBtn {
        bottom: 5rem;
        right: 2%;
    }
}