.download_section{
    margin-top: 8rem;
}
.part-1 h2{
    margin-bottom: 2rem;
}
.part-1 p{
    margin-bottom: 1rem;
}


