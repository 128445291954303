.choose_section {
    margin-top: 8rem;
}

.about_main_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    overflow: hidden;
}


.content_section h2 {
    margin-bottom: 2rem;
}

.content_section h2>strong {
    color: var(--primary);
}

.title span {
    color: var(--primary);
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 4rem;
    font-weight: 500;
}

.title small::after {
    content: '';
    display: block;
    width: 3rem;
    height: 0.6rem;
    border-bottom: 2px solid var(--primary);
    position: absolute;
    margin-top: 0.6rem;
}

.title small::before {
    content: '';
    display: block;
    width: 2rem;
    height: 0.8rem;
    border-top: 2px solid var(--primary);
    position: absolute;
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.title h4 {
    color: var(--primary);
    font-weight: 600;
    line-height: 1.2;
    font-size: 3.5rem;
}

.main_inner_section {
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr 7fr;
    gap: 1rem;
    margin-bottom: 3.5rem;
}

.main_inner_section img {
    max-width: 80%;

}

.img_section {
    place-content: center;
    display: grid;
}

.main_inner_content h4 {
    color: var(--secondary);
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: capitalize;
}

.main_inner_content P {
    color: var(--secondary);
    font-size: 1.2rem;
    font-weight: 400;
}

/* Media Query */

@media (max-width:1024px) {
    .about_main_section {
        grid-template-columns: 1fr;
    }

    .img_section {
        display: flex;
        margin: 0 auto;
        width: 89%;
        margin-top: 5rem;
    }
    .content_section h2 {
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 2rem;
    }
    .main_inner_content h4{
        font-size: 1.25rem;
        font-weight: 600;
    }
    .main_inner_content P{
        font-size: 1rem;
    }

    .main_inner_section {
        display: grid;
        overflow: hidden;
        grid-template-columns: 1fr 4fr;
        gap: 1rem;
        margin-bottom: 1.5rem;
    }
}

@media (max-width:500px) {
    .content_section span {
        font-size: .75rem;
        font-weight: 600;
    }
    .content_section h2 {
        font-size: 1.5rem;
    }
    .choose_section {
        margin-top: 3rem;
    }    
    .img_section {
        margin-top: 2rem;
    }
    .main_inner_content P {
        font-size: .85rem;
        line-height: 1.4rem;
    }
    .title small::before{        
        width: 1rem;
    }
    .title small::after {
        width: 2rem;
    }
    .title span {
        margin-left: 3rem;
        font-size: .85em;
    }
}

