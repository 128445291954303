nav {
    height: 5rem;
    width: 100vw;
    background-color: var(--white);
    display: grid;
    place-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0px 2px 10px 0px rgba(0, 112, 226, 0.05) inset, 0px 4px 4px 0px rgba(0, 112, 226, 0.20);
}

.nav__toggle-btn {
    display: none;
}

.nav__container {
    height: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.logo {
    width: 8rem;
    display: block;
}

.nav__links {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.nav__links a {
    color: var(--secondary);
    transition: var(--transition);
}

.nav__links a:hover {
    color: var(--primary);
}
.TraningModuleBtn{
    display: inline-flex;
    background-color: var(--white);
    color: red;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    border: 2px solid Red;
    cursor: pointer;
}
.TraningModuleBtn svg{
    margin-left: 0.5rem;
    /* font-size: 1.2rem; */
}
.TraningModuleBtn:hover{
    background-color: red;
    color: var(--white);

}
.active-nav {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--primary) !important;
    /* box-shadow: 0px 2px 10px 0px rgba(0, 112, 226, 0.05) inset, 0px 4px 4px 0px rgba(0, 112, 226, 0.20); */
}
.active-nav::after {
    content: '';
    display: block;
    width: 2.2rem;
    height: 0.1rem;
    background: var(--primary);
    position: absolute;
}
#google_translate_element select{
    margin-top: 25px;
    margin-left: 15px;
    width: 20px;
    color: var(--white);
    background: transparent;
}
#google_translate_element select option{
    background: var(--secondary);
}

#google_translate_element {
    top: 1rem;
    right: 0%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 5rem;
    height: 2.5rem;
    background-color: var(--secondary);
    border-radius: 0.5rem;
    cursor: pointer;
    box-shadow: var(--box-shadow);
    z-index: 100 !important;
    /* visibility: hidden; */
  }
  #google_translate_element span{
    display: none;
  }
  .goog-te-banner-frame {
    display: none;
  }
  .goog-logo-link{
    display: none !important;
  }
  .goog-te-gadget{
    color: transparent !important;
  }
.VIpgJd-ZVi9od-ORHb-OEVmcd{
    visibility: hidden !important;
}
/* =========Media Querries ======= */
@media screen and (max-width:1340px) {
    .nav__links {
        gap: 1.5rem;
    }
}
@media screen and (max-width:1200px) {
    .nav__toggle-btn {
        display: inline-block;
        background-color: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }
    .TraningModuleBtn{
        margin-top: 1rem;
    }
    .nav__toggle-btn svg {
        color: var(--primary);
    }

    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        perspective: 400px;
        align-items: start;

    }

    .active-nav {
        color: var(--secondary) !important;
        box-shadow: 0px 2px 10px 0px rgba(0, 112, 226, 0.05) inset, 0px 4px 4px 0px rgba(0, 112, 226, 0.20);
    }

    .active-nav,
    .active-nav::after {
        display: none;
    }

    .nav__links li {
        height: 4rem;
        width: 100%;
        box-shadow: -2rem 2rem 5rem rgba(0, 0, 0, 0.4);      
    }
    .nav__links li a{
        background-color: var(--primary) !important;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1rem 5rem 1rem 3rem;
    }
    .show__nav {
        display: flex;
    }

    .hide__nav {
        display: none;
    }
    .nav__links a {
        color: var(--white);
    }
    
    .nav__links a:hover {
        color: var(--secondary);
        font-weight: 400;
    }
}