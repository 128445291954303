.homebookdemo__Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5rem;
    margin-top: 8rem;
    overflow-x: hidden;
}
.bookdemo_content{
    /* padding: 1rem 2rem; */
    overflow-x: hidden;
}

.bookdemo_content h5 {
    color: var(--primary);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}

.bookdemo_content h1 {
    color: var(--secondary);
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 1rem 0rem;
    text-transform: capitalize;
}

.bookdemo_content .text {
    color: var(--ternary);
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 2rem;    
}

.bookdemo_content .call_section {
    display: grid;
    grid-template-columns: 1fr 6fr;
    overflow-x: hidden;
    margin: 1.5rem 0rem;
    /* width: 100%; */
    /* border-radius: 0.5rem; */
}

.bookdemo_content .call_section img {
    max-width: 70%;
}

.rating_content {
    -webkit-transform: skew(-358deg);
}

.rating_content h3 {
    color: var(--secondary);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    margin-bottom: .25rem;
}

.rating_content p {
    color: var(--secondary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
}

.bookdemo_content .call_section .call_text h4 {
    color: var(--primary);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.625rem;
    margin-bottom: .5rem;
}

.bookdemo_content .call_section .call_text p {
    color: var(--primary);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.625rem;
}

.bookdemo_form {
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    overflow-x: hidden;
    text-align: center;
    background: var(--primary);
}

.form_content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}
.form_input{
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
/* 

input {
    padding: 15.85px 41.956px 15.85px 23.309px;
    border-radius: 30px;
    margin: .5rem 0.25rem;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    color: #74787C
} */

.btn {
    grid-column: 1 / span 2;
    position: relative;
    border-radius: 30px;
    padding: 15.85px 41.956px 15.85px 23.309px;
    margin: .5rem 0.25rem;
    color: var(--secondary);
    text-align: center;
    font-size: 13.053px;
    font-weight: 600;
    line-height: 13.053px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn:hover {
    background-color: var(--secondary);
    color: var(--white);
}

.bookdemo_form h1 {
    -webkit-transform: skew(-358deg);
}

.bookdemo_form h1>span {
    color: var(--white);
}

.rating_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-x: hidden;
    margin-top: 3rem;
    border-radius: 0.5rem;
    background-color: var(--light-primary);
    padding: 2.2rem;
    -webkit-transform: skew(358deg);
}

.rating_section .rating_img {
    max-width: 80%;
    -webkit-transform: skew(-358deg);
}

/* ====CSS for Media Query==== */

@media (max-width:1440px) {
    .bookdemo_form{
        margin: 0rem;
    }
    .bookdemo_form h1{
        margin-top: 3rem;
    }
}

@media (max-width:1024px) {
    .homebookdemo__Container{
        grid-template-columns: 1fr;
        gap: 5rem;
    }
    .bookdemo_form h1{
        color: var(--primary);
        -webkit-transform: skew(358deg);
    }
    .bookdemo_form {
        background-image: none;
        background: var(--light-primary);
        border-radius: 1rem;
    }   
    .form_content{
        padding: 2.4rem 5rem;
        row-gap: 0.75rem;
        column-gap: 1rem;
    }
    .bookdemo_content h5{        
        font-weight: 500;
    } 
    .rating_section{
        padding: 2rem;
        -webkit-transform: none;
        width: 80%;
    }
    .rating_section .rating_img {
        -webkit-transform: none;
    }
    .rating_content{
        -webkit-transform: none;
    }    
/* 
    .bookdemo_content {
        margin: 0rem 1rem 4rem 1rem;
    } */

    /* .bookdemo_form {
        margin: 0rem 1rem;
    } */
}


@media (max-width:600px) {
    .rating_section{
        padding: 1rem;
        -webkit-transform: none;
        width: 100%;
    }
    .rating_section .rating_img {
        -webkit-transform: none;
    }
    .rating_content{
        -webkit-transform: none;
    }
}

@media (max-width:425px) {
    .rating_section .rating_img{
        -webkit-transform: none;
    }
    .bookdemo_content h1{
        font-size: 2rem;
        -webkit-transform: none;
    }
    .bookdemo_content .text {
        font-size: 1.1rem;
        -webkit-transform: none;
    }
    .rating_section{
        grid-template-columns: 1fr;
        -webkit-transform: none;
        row-gap: 1.5rem;
        place-items: center;
        text-align: center;
    }
    .bookdemo_content h5{
        font-size: .85rem;
    }
    .bookdemo_content .call_section{
        grid-template-columns: 1fr 3fr;
        place-items: center;
    }
    .bookdemo_content .call_section .call_text h4{
        font-size: 1rem;
        margin: 0rem;
        align-items: center;
        
    }
}
