.services__container {
    margin-top: 5rem;
    overflow-x: hidden;
}

.centre-heading,
.top-heading {
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.top-heading {
    color: var(--primary);
}

.top-heading h5 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.75rem;
}

.centre-heading h1 {
    color: var(--secondary);
    font-size: 2.5rem;
}

.services__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;
}

.service__content .explore__more h6 {
    color: var(--primary);
    font-size: 1rem;
    display: flex;
    align-items: right;
    justify-content: right;
    margin-right: 5%;
}

.service__content .explore__more h6 img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
}

.service__img img {
    width: 70%;
}

.service__content {
    grid-template-columns: 1fr;
}

.services__card__wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.service__card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    width: 9rem;
    height: 9rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
    border-radius: 1rem;
    box-shadow: var(--service-box-shadow)
}

.service__card img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem auto;
    margin-top: 1rem;
    width: 40%;
}

.service__card h5 {
    text-align: center;
    width: 70%;
    color: var(--secondary);
    font-size: 1.1rem;
    line-height: 1.5;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    font-weight: 500;

}


@media (max-width:1200px) {
    .services__wrap {
        grid-template-columns: 1fr;
    }

    .service__img img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 50%;
    }

    .centre-heading h1 {
        font-size: 1.8rem;
        line-height: 1.5;
    }
}

@media (max-width:896px) {
    .services__card__wrap {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width:640px) {
    .services__card__wrap {
        grid-template-columns: 1fr 1fr;
    }

    .service__img img {
        width: 80%;
    }
}
@media(max-width:500px){
    .centre-heading h1 {
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: center;
    }
    .top-heading h5 {
        font-size: .75rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
}

@media (max-width:410px) {
    .services__card__wrap {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .service__card {
        width: 90%;
        height: 12rem;
    }

}