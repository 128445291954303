.backend_section{
    margin-top: 5rem;
}
.heading h3{
    text-align: center;
    font-size: 1.6rem;    
    font-weight: 500;

}
.logo_section{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin: auto;
    width: 60%;
    margin-top: 2rem;
}
.logo_img img{
    padding: 1rem;
}
.logo_img:hover{
    background: rgba(191, 191, 191, 0.125);
}
@media (max-width:1250px){
    .logo_section{
        width: 90%;
    }
}
@media (max-width:1024px){
    .logo_section{
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 0rem;
    }
}
@media (max-width:1024px){
    .logo_section{
        row-gap: 2rem;
    }
}
@media (max-width:425px){
    .logo_section{
        grid-template-columns: 1fr 1fr;
    }
    .logo_img img {
        padding: .5rem;
    }
}
@media (max-width:425px){
    .logo_section{
        grid-template-columns: 1fr;
        row-gap: 1rem;
    }
    .logo_img img {
        padding: 1rem;
    }
}