.hero__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;
    margin-top: 8rem;
    overflow-x: hidden;
}

.part-1 h4 {
    color: var(--primary);
    margin-left: 4rem;
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
}

.part-1 small::after {
    content: '';
    display: block;
    width: 3rem;
    height: 0.7rem;
    border-bottom: 2px solid var(--primary);
    position: absolute;
    margin-top: 0.4rem;

}

.part-1 small::before {
    content: '';
    display: block;
    width: 2rem;
    height: 0.8rem;
    border-top: 2px solid var(--primary);
    position: absolute;
    margin-left: 1rem;
    margin-top: 0.5rem;

}

.part-1 h1 {
    color: var(--secondary);
    font-weight: 600;
    line-height: 1.2;
    font-size: 3.5rem;
    margin: 1rem 0rem;
}

.part-1 h2 {
    color: var(--secondary);
    font-weight: 600;
    line-height: 1.5;
    font-size: 2.5rem;

}

.part-1 p {
    color: var(--ternary);
    font-weight: 400;
    font-size: 1.5rem;
}

.download__store {
    width: 60%;
}

.part-2 {
    width: 70%;
    align-items: center;
    justify-self: center;
}

/* ===Media Queries=== */
@media (max-width:1640px) {
    .hero__container {
        gap: 2rem;
    }
}

@media (max-width:1440px) {
    .part-1 h1 {
        font-size: 3rem;
    }

    .part-2 {
        width: 100%;
    }

    .download__store {
        width: 80%;
    }
}

@media (max-width:1024px) {
    .hero__container {
        gap: 2rem;
    }

    .part-1 h1 {
        font-size: 2rem;
        line-height: 1.5;

    }

    .part-1 h2 {
        font-size: 1.8rem;
        line-height: 1.5;

    }

    .part-1 p {
        font-size: 1.2rem;
        line-height: 1.7
    }

    .download__store {
        width: 90%;
    }
}

@media (max-width:768px) {
    .hero__container {
        gap: 2rem;
    }

    .part-1 h1 {
        font-size: 2rem;
        line-height: 1.5;

    }

    .part-1 h2 {
        font-size: 1.6rem;
        line-height: 1.6;

    }

    .part-1 p {
        font-size: 1rem;
        line-height: 1.8
    }

    .download__store {
        width: 100%;
    }
}

@media (max-width:700px) {
    .hero__container {
        grid-template-columns: 1fr;
    }
}

@media (max-width:425px) {
    .hero__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .download__store {
        width: 80%;
    }

    .part-2 {
        width: 100%;
    }
}


@media (max-width:320px) {
    .hero__container {
        gap: 3rem;
    }

    .part-1 h1 {
        font-size: 1.7rem;
        line-height: 1.5;
    }
    .part-1 small::before {
        width: 1rem;
    }
    .part-1 small::after{
        width: 2rem;
    }
    .part-1 h4{
        font-size: .75rem;
        margin-left: 3rem;
    }
    .part-1 h2 {
        font-size: 1.5rem;
        line-height: 1.4;
        margin-bottom: 1rem;
    }

    .part-1 p {
        font-size: 0.8rem;
        line-height: 1.8
    }

    .download__store {
        width: 90%;
    }

    .part-2 {
        width: 100%;
    }
}