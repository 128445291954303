.app_section {
    margin-top: 5rem;
}

.app_store {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5rem;
    overflow: hidden;
    /* border: 1px solid; */
}

.android_store {    
    border-radius: 5px;
    background: rgba(50, 197, 244, 0.20);
    box-shadow: 0px 4px 4px 0px rgba(50, 197, 244, 0.20) inset, 0px 4px 4px 0px rgba(50, 197, 244, 0.20);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5rem;
    padding: 2rem;
    /* height: 200px; */
}
.download_button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.download_button .button{
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 6px;
    border: 1px solid #1C91C3;
    cursor: pointer;
    background: none;
    color: #1C91C3;
    font-size: .85rem;
}
.logo_img{
    display: flex;
    align-items: center;
}
.code_img{
    display: block;
    max-width: 60%;
    margin: auto;
}



/* Meida Query */
@media (max-width:1440px){
    .android_store{
        column-gap: 1rem;
    }

}

@media (max-width:1024px){
    .app_store {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }
   
}
@media (max-width:425px){
    .download_button button{
        padding: .5rem 1rem;
    }
    .android_store{
        padding: 2.5rem;
    }
    .code_img{
        margin-top: 2rem;
    }
    .android_store {
        display: flex;
        flex-direction: column;
    }
}
@media (max-width:320px){
    .android_store{
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }    
}