.Privacy_policy_Container{
    margin-top: 8rem;
}
.content__wrapper p{
    color: var(--secondary);
    opacity: 0.7;
    padding: 0.2rem 0;
    text-transform: initial;
}
.content__wrapper p strong{
    font-weight: 600;
}

.content__wrapper h6{
    margin-top: 1rem;
    font-size: 1.2rem;
    padding: 0.3rem 0;
    color: var(--secondary);
    opacity: 0.9;
    text-transform: capitalize;
}
@media (max-width:425px) {
    .content__wrapper p{
        text-align: justify;
    }
}
