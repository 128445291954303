.form__container {
    margin-top: 10rem;
}

.form_section {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 5rem;
    overflow-x: hidden;
    place-items: center;
}
.form_main {
    padding: 3rem 4rem;
    border-radius: 0.5rem;
    background: var(--white);
    border: 2px solid var(--light-primary);
}
.form__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form__content img{
    width: 70%;
    margin-bottom: 2rem;
}
.contact_detail {
    background: var(--light-primary);
    padding: 2rem;
    width: 80%;
    border-radius: 0.75rem;
    box-shadow: var(--box-shadow);
}

.contact_detail h2 {
    color: var(--secondary);
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.contact_detail p {
    color: var(--secondary);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 1rem;
}

.form_main input,
.form_main textarea {
    padding: 1rem 2rem;
    border-radius: 0.7rem;
    border: 0.767px solid rgba(29, 45, 92, 0.30);
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 0.7rem;
}

input::placeholder {
    color: rgba(29, 45, 92, 0.30);
}

textarea::placeholder {
    color: rgba(29, 45, 92, 0.30);
}

.form_input {
    margin-bottom: 1rem;
}

.submit-btn {
    padding: 1.2rem 4.5rem;
    cursor: pointer;
    border-radius: 0.7rem;
    background: var(--primary);
    color: var(--white);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;
    width: 100%;
}

.submit-btn:hover {
    background: var(--secondary);
}

.privacy_content p {
    margin-top: 2rem;
    color: rgba(29, 45, 92, 0.60);
    font-family: Poppins;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
}
.privacy_content p>span a{
    color: var(--primary);
}
.centre-heading{
    color: var(--secondary);
}
.mb-3{
    margin-bottom: 3rem;
}
.mb-3 h1{
    font-weight: 500;
    text-align: center;
}
.contact_detail .contact-footer-info .contact-info img{
    margin-top: 0.5rem;
    width: 1rem;
}
.SuccesFullySend{
    color: var(--secondary);
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: -1rem;
    display: none;
}
@media (max-width:1024px) {
   
    .form_section{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .form_main{
        width: 100%;
        order: -1;
    }
    .form_main .form_input{
        width: 100%;
    }

}
@media (max-width:768px) {
    .form__container {
        margin-top: 6rem;
    }
    .form__content img{
        width: 100%;
    }
    .contact_detail{
        width: 100%;
    }
}

@media (max-width:571px) {
    .form_main{
        padding: 3rem 2rem;
    }
}
@media (max-width:491px) {
    .form_main input,
    .form_main textarea {
        width: 100%;
    }
    .form_main{
        padding: 2rem 1rem;
        box-shadow: none;
    }
}
@media (max-width:400px) {
    .submit-btn{
        font-size: 0.7rem;
        padding: 1.2rem 2.5rem;

    }
    .form_main{
        padding: 2rem 2rem;
    }
    .contact_detail{
        padding: 1rem;
    }
    .contact_detail p{
        font-size: 0.8rem;
        line-height: 1.5rem;
    }
}
@media (max-width:350px) {
    .form_main input,
    .form_main textarea , .submit-btn, .privacy_content p {
        width: 100%;
    }
    .submit-btn{
        font-size: 0.7rem;
    }
    .form_main{
        padding: 2rem 1rem;
    }
}

