.about__container{
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 5rem;
    margin-top: 10rem;
    overflow-x: hidden;
}
.about_img{
    width: 100%;
}
.about_img img{
    width: 100%;
}
.about_inner {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-top: 2rem;
    overflow-x: hidden;
    background: var(--light-primary);
    padding: 0rem 1rem;
    width: 80%;
    gap: 2rem;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
}

.about_inner .inner-1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_inner .inner-2 {
    /* margin-left: 1rem; */
}
.about_inner .inner-2 li{
    color: var(--secondary);
}

@media (max-width:1440px){
    /* .part-1 p{
        font-size: 1rem;
    } */
    .about_inner{
        width: 100%;
        padding: 1rem;
    }
    .about_inner .inner-2 li {
        font-size: .95rem;
        line-height: 1.65rem;
    }
}
@media (max-width:1200px) {
    .about__container {
        gap: 2rem;
    }
    .about_img {
        width: 100%;
    }
    .about_inner{
        grid-template-columns: 1fr;
        width: 80%;
    }
    .about_inner .inner-1{
        display: none;
    }
    .about_inner .inner-2 {
        margin-left: 0.5rem;
    }
    
}
@media (max-width:1024px) {
    .about__container {
        gap: 5rem;
    }
}
@media (max-width:768px) {
    .about__container{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin-top: 6rem;
    }    
}
@media (max-width:700px) {
    .about_inner {
        width: 100%;
    }
    .about__image{
        order: 1;
        margin: 3rem 0 0rem 0 !important;
    }
    .about__container{
        grid-template-columns: 1fr;
    }    
}
@media (max-width:550px){
    .about__container{
        gap: 0rem;
    }
    .part-1 h4 {
        font-size: .75rem;
    }
    .part-1 h2 {
        font-size: 1.5rem;
        line-height: 1.5;
    }    
}
@media (max-width:375px){
    .about_inner .inner-2 li {
        font-size: .85rem;
        line-height: 1.5rem;
    }
}